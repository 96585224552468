import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
} from '@dnd-kit/core';
import { useContext, useEffect, useState } from 'react';
import { WBMLProduct as WBMLProductAPI } from '../../../api/wbml';
import { useUnityBridge } from '../../../hooks/useUnityBridge';
import { WBMLProduct } from '../../../models/wbml';
import { AppLayoutContext } from '../AppRoot/AppRoot';
import DraggableProductItem from './DraggableProductItem';
import DroppableUnityPlayer from './DroppableUnityPlayer';
import ProductsPanel from './ProductsPanel';
import {
  convertToUnityCoordinates,
  getMousePosition,
  getProductBoundingBox,
  getProductCenterPoint,
} from './helpers';

const PlanogramView = () => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const [activeProduct, setActiveProduct] = useState<WBMLProduct | null>(null);
  const [showProductsPanel, setShowProductsPanel] = useState(false);
  const [panelPosition, setPanelPosition] = useState<{
    xMin: number;
    xMax: number;
    yMin: number;
    yMax: number;
  } | null>(null);
  const [isOverUnityPlayer, setIsOverUnityPlayer] = useState(false);

  const { hideProductLibrary, spawnProduct } = useUnityBridge({
    onShowProductLibrary: (position) => {
      console.log('Message received from Unity');
      setPanelPosition(position);
      setShowProductsPanel(true);
    },
    onHideProductLibrary: () => {
      console.log('Hide message received from Unity');
      setShowProductsPanel(false);
      setPanelPosition(null);
    },
  });

  const { sidebarCollapsed } = useContext(AppLayoutContext);

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string);
    setActiveProduct(event.active.data.current as WBMLProduct);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const centerPoint = getProductCenterPoint(event, sidebarCollapsed!);
    const boundingBox = getProductBoundingBox(event);

    if (centerPoint && boundingBox && activeProduct) {
      const productWidth = parseFloat(activeProduct?.width || '0');
      const productHeight = parseFloat(activeProduct?.height || '0');

      const unityPosition = convertToUnityCoordinates(
        centerPoint.x,
        centerPoint.y,
        window.innerHeight,
        centerPoint.sidebarWidth,
        productWidth,
        productHeight
      );

      const spawnData = {
        model_id: activeProduct.id,
        name: activeProduct.name || '',
        height: String(activeProduct?.height || 0),
        width: String(activeProduct?.width || 0),
        depth: String(activeProduct?.depth || 0),
        mouseX: unityPosition.x,
        mouseY: unityPosition.y,
      };

      spawnProduct(spawnData);
      console.log('Spawning product with data:', spawnData);
    }

    setActiveId(null);
  };

  const handleCloseProductsPanel = () => {
    setShowProductsPanel(false);
    hideProductLibrary();
    if ((window as any).unityInstance?.canvas) {
      (window as any).unityInstance.canvas.focus();
    }
  };

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (!isOverUnityPlayer) return;
      const position = getMousePosition(event, sidebarCollapsed!);
      const unityPosition = convertToUnityCoordinates(
        position.x,
        position.y,
        window.innerHeight,
        position.sidebarWidth
      );
      console.warn('zz Unity coordinates:', unityPosition);
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [sidebarCollapsed, isOverUnityPlayer]);

  // useEffect(() => {
  //   const logMousePosition = (e: any) => {
  //     console.log(`ww Mouse Position: (${e.clientX}, ${e.clientY})`);
  //   };

  //   window.addEventListener('mousemove', logMousePosition);

  //   return () => {
  //     window.removeEventListener('mousemove', logMousePosition);
  //   };
  // }, []);

  return (
    <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <DroppableUnityPlayer onIsOverChange={setIsOverUnityPlayer} />
      {showProductsPanel && (
        <ProductsPanel
          onClose={handleCloseProductsPanel}
          position={panelPosition || undefined}
        />
      )}
      <DragOverlay>
        {activeId && activeProduct ? (
          <DraggableProductItem
            product={activeProduct as WBMLProductAPI}
            index={activeProduct.index || 0}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default PlanogramView;
