import { useCallback, useEffect } from 'react';

export interface ProductSpawnData {
  model_id: string;
  name: string;
  height: string;
  width: string;
  depth: string;
  mouseX: number;
  mouseY: number;
}

interface PanelPosition {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
}

interface UnityBridgeCallbacksProps {
  onShowProductLibrary?: (position: PanelPosition) => void;
  onHideProductLibrary?: () => void;
  onProductPlaced?: (
    productId: string,
    position: { x: number; y: number; z: number }
  ) => void;
  onProductDeleted?: (productId: string) => void;
  onExportImage?: (imageBytes: Uint8Array, fileName: string) => void;
  onExportCSV?: (csvString: string, fileName: string) => void;
  onExportSegment?: (segmentFile: Uint8Array, segmentName: string) => void;
}

declare global {
  interface Window {
    unityBridge?: {
      showProductLibrary: () => void;
      hideProductLibrary: () => void;
      onProductPlaced: (productId: string, position: string) => void;
      onProductDeleted: (productId: string) => void;
      exportImage: (imageBytes: Uint8Array, fileName: string) => void;
      exportCSV: (csvString: string, fileName: string) => void;
      exportSegment: (segmentFile: Uint8Array, segmentName: string) => void;
    };
    ShowProductLibrary: () => void;
    HideProductLibrary: () => void;
    ExportImage: (imageBytes: Uint8Array, fileName: string) => void;
    ExportCSV: (csvString: string, fileName: string) => void;
    ExportSegment: (segmentFile: Uint8Array, segmentName: string) => void;
    SpawnProduct: (modelId: string) => void;
  }
}

export const useUnityBridge = (callbacks: UnityBridgeCallbacksProps) => {
  useEffect(() => {
    const handleUnityMessage = (event: MessageEvent) => {
      switch (event.data.type) {
        case 'SHOW_PRODUCT_LIBRARY':
          callbacks.onShowProductLibrary?.(event.data.position);
          break;
        case 'HIDE_PRODUCT_LIBRARY':
          callbacks.onHideProductLibrary?.();
          break;
      }
    };

    window.addEventListener('message', handleUnityMessage);
    return () => window.removeEventListener('message', handleUnityMessage);
  }, [callbacks]);

  const hideProductLibrary = useCallback(() => {
    const unityFrame = document.querySelector('iframe');
    if (unityFrame?.contentWindow) {
      unityFrame.contentWindow.HideProductLibrary();
    }
  }, []);

  const spawnProduct = useCallback((productData: ProductSpawnData) => {
    const jsonString = JSON.stringify(productData);
    const unityFrame = document.querySelector('iframe');

    if (unityFrame?.contentWindow) {
      unityFrame.contentWindow.SpawnProduct(jsonString);
    } else if ((window as any).unityInstance) {
      (window as any).unityInstance.SendMessage(
        'JSProductSpawner',
        'SpawnProduct',
        jsonString
      );
    }
  }, []);

  return {
    hideProductLibrary,
    spawnProduct,
  };
};
