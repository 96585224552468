import { DragEndEvent } from '@dnd-kit/core';

export const getMousePosition = (
  event: MouseEvent,
  sidebarCollapsed: boolean
) => {
  const sidebarWidth = sidebarCollapsed ? 80 : 250;
  return {
    x: event.clientX - sidebarWidth,
    y: event.clientY,
    sidebarWidth,
  };
};

export const getProductCenterPoint = (
  event: DragEndEvent,
  sidebarCollapsed: boolean
) => {
  const { active } = event;
  if (!active?.rect?.current?.translated) return null;

  const currentRect = active.rect.current.translated;
  const sidebarWidth = sidebarCollapsed ? 80 : 250;

  return {
    x: currentRect.left + currentRect.width / 2,
    y: currentRect.top + currentRect.height / 2,
    sidebarWidth,
  };
};

export const getProductBoundingBox = (event: DragEndEvent) => {
  const { active } = event;
  if (!active?.rect?.current?.translated) return null;

  return active.rect.current.translated;
};

export const convertToUnityCoordinates = (
  mouseX: number,
  mouseY: number,
  viewportHeight: number,
  sidebarWidth: number,
  productWidth = 0,
  productHeight = 0
) => {
  return {
    x: Math.round(mouseX - sidebarWidth - productWidth / 2),
    y: Math.round(viewportHeight - mouseY - productHeight / 2),
  };
};
