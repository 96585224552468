import { Spin, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useAppSelector } from '../../../store';
import { WBMLProduct as WBMLProductAPI } from '../../../api/wbml';
import DraggableProductItem from './DraggableProductItem';
import './SearchResults.less';

const { Text } = Typography;

interface WBMLProduct {
  id: string;
  upc: string;
  name: string;
  size: string;
  model_name: string;
  count: string;
  paths: {
    image: string;
    object: string;
  };
  brand_name: string;
  manufacturer_name: string;
  parent_company_name: string;
  category_name: string;
  market_name: string;
}

interface SearchResultsProps {
  searchQuery: string;
  resultCount: number;
  products: WBMLProduct[];
  sortOrder: 'asc' | 'desc';
  selectedCategory?: string;
}

const SearchResults: React.FC<SearchResultsProps> = ({
  searchQuery,
  resultCount,
  products,
  sortOrder,
  selectedCategory,
}) => {
  const { loading, categories } = useAppSelector((state) => state.wbml);

  const categoryName = useMemo(() => {
    if (!selectedCategory) return '';
    const category = categories.find((c) => c.id === selectedCategory);
    return category?.name || selectedCategory;
  }, [selectedCategory, categories]);

  const sortedProducts = useMemo(() => {
    return [...products].sort((a, b) => {
      const upcA = a.upc || '';
      const upcB = b.upc || '';
      return sortOrder === 'asc'
        ? upcA.localeCompare(upcB)
        : upcB.localeCompare(upcA);
    });
  }, [products, sortOrder]);

  const resultsText =
    searchQuery === ''
      ? `Category: ${categoryName} (${resultCount})`
      : `"${searchQuery}" (${resultCount})`;

  return (
    <>
      <Text strong className="results-text">
        Results for {resultsText}
      </Text>
      <div className="products-grid">
        {loading ? (
          <div className="loading-container">
            <Spin spinning={loading} />
            <Text>Loading products...</Text>
          </div>
        ) : (
          sortedProducts.map((product, index) => (
            <DraggableProductItem
              key={product.id}
              product={product as WBMLProductAPI}
              index={index}
            />
          ))
        )}
      </div>
    </>
  );
};

export default SearchResults;
